<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="row hero-content">
					<div class="columns column12">
						<div class="content">
							<p class="subtitle">
								<span>{{ defaults[locale].homepage.headingSubTitle }}</span>
							</p>
							<p class="title">
								<span>{{ defaults[locale].homepage.headingTitle }}</span>
							</p>
						</div>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :content="page.content" :booking-id="page.bookingID" />

		<section class="booking-benefits">
			<div class="row">
				<div class="columns column4 left bg-image-content">
					<h2>{{ defaults[locale].homepage.sectionBenefitsTitle }}</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].homepage.bookingBenefits" :key="key">
							<font-awesome-icon icon="fa-light fa-circle-check" size="1x" />{{ benefit }}
						</li>
					</ul>
					<book-button class="button button-cta reserve-now">
						{{ $t('bookNow') }}
					</book-button>
				</div>
			</div>
			<picture>
				<source
					v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
					:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
				<img
					class="bg-image"
					:src="defaults[locale].homepage.sectionBenefitsImage"
					:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks :items="promoblocksData" :amount="3">
			<template #header>
				<div
					v-parse-links
					class="columns column12"
					v-html="defaults[locale].homepage.sectionPromotionContent"
				/>
			</template>
		</promoblocks>

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks" />

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	right: 0;
	z-index: 4;

	.content {
		color: #fff;
		text-align: center;
		max-width: 80%;
		margin: 0 auto;
		animation: fade-in 1500ms ease-out;
		font-family: var(--heading-font-family);

		p {
			margin: 0;
		}

		.title {
			font-size: 5em;
			color: #fff;
			font-weight: 300;
		}

		.subtitle {
			font-weight: 400;
			font-size: 20px;
			line-height: 100%;
			letter-spacing: 0.15em;
			text-transform: uppercase;
			color: #fff;
		}
	}
}

.booking-benefits {
	position: relative;
	padding: 200px 0;

	ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			line-height: 36px;
			vertical-align: middle;

			svg {
				margin: 0 7px 0 0;
				font-size: 20px;
				display: inline-block;
			}
		}
	}
}

.bg-image {
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	z-index: 1;
}

.bg-image-content {
	background: var(--contentblock-background-color);
	padding: 50px;
	z-index: 3;
}
</style>
